import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "pubtrack-frontend-library";


export async function getWeeklyTransactions() {
    return await axiosInstance
        .get(`/tracker/reports/weekly/transactions`)
        .catch(handleErrors)
};

export async function getLastMonthTransactions() {
    return await axiosInstance
        .get(`/tracker/reports/lastmonth/transactions`)
        .catch(handleErrors)
};


export async function getWeeklyArticles() {
    return await axiosInstance
        .get(`/tracker/reports/weekly/articles`)
        .catch(handleErrors)
};

export async function getLastYearArticles() {
    return await axiosInstance
        .get(`/tracker/reports/lastyear/articles`)
        .catch(handleErrors)
};

export async function getArticlesWithoutIdentifiers() {
    return await axiosInstance
        .get(`/tracker/reports/articles/withoutidentifiers`)
        .catch(handleErrors)
};

export async function getArticlesInProduction() {
    return await axiosInstance
        .get(`/tracker/reports/articles/production`)
        .catch(handleErrors)
};

export async function getArticlesPerInstitution() {
    return await axiosInstance
        .get(`/tracker/reports/institutions/articles`)
        .catch(handleErrors)
};


export async function getInstitutions() {
    return await axiosInstance
        .get(`/tracker/reports/weekly/institutions`)
        .catch(handleErrors)
};

export async function getAgreements() {
    return await axiosInstance
        .get(`/tracker/reports/weekly/agreements`)
        .catch(handleErrors)
};
