import React, { useEffect, useState } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { withFormik, Field } from "formik";
import { PieChart, Table, Card, Loader, ErrorMessage, AsyncInput, BarChart, NavigationTabs, ArticleDetailsModal, CustomTable, loadRorOptions } from "pubtrack-frontend-library";
import { transformData, getMessageDetails, getArticleDetails, getInstitutionArticlesPerMonth, getInstitutionJournals, getFunderArticles, getInstitutionMessages, getArticlesPerFunder } from "../../helpers";
import { articlesPerInstitutionColumns, publishedArticlesColumns, inPipeLineArticlesColumns, funderMessagesColumns } from "./columnsData";
import { userSettings } from "../../user-settings";
import { itemsPerPage } from "../../constants";

const Funders = () => {
    const [sortBy, setSortBy] = useState("id");
    const [sortDir, setSortDir] = useState("asc");
    const [loading, setLoading] = useState(false);
    const [selectedFunder, setSelectedFunder] = useState(null);
    const [error, setError] = useState(false);

    const [articlesPerFunder, setArticlesPerFunder] = useState([]);

    const [institutionArticlesPerMonth, setInstitutionArticlesPerMonth] = useState(null);
    const [institutionJournals, setInstitutionJournals] = useState(null);

    const [inPipeLineArticles, setInPipelineArticles] = useState(null);
    const [publishedArticles, setPublishedArticles] = useState(null);

    const [isArticleModalOpen, setIsArticleModalOpen] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [selectedArticleDetails, setSelectedArticleDetails] = useState(null);

    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [selectedMessageDetails, setSelectedMessageDetails] = useState(null);

    useEffect(() => {
        if (selectedFunder) {
            setLoading(true);

            const fetchData = async () => {
                try {
                    const [articlesPerMonthResponse, journalsResponse, inPipelineArticlesResponse, publishedArticlesResponse] = await Promise.all([
                        getInstitutionArticlesPerMonth(encodeURIComponent(selectedFunder)),
                        getInstitutionJournals(encodeURIComponent(selectedFunder)),
                        getFunderArticles(encodeURIComponent(selectedFunder), 'production'),
                        getFunderArticles(encodeURIComponent(selectedFunder), 'published'),
                    ]);
                    setInstitutionArticlesPerMonth(articlesPerMonthResponse.data);
                    setInstitutionJournals(journalsResponse.data);
                    setInPipelineArticles(inPipelineArticlesResponse.data);
                    setPublishedArticles(publishedArticlesResponse.data);
                } catch (error) {
                    setError(true);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [selectedFunder]);

    useEffect(() => {
        const fetchArticlesPerFunder = async () => {
            setLoading(true);
            try {
                const response = await getArticlesPerFunder();
                setArticlesPerFunder(response.data);
            } catch {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchArticlesPerFunder();
    }, []);

    const handleSelectArticle = row => {
        const id = row && row.original ? row.original.id : null;
        setSelectedArticle(id);
    };

    const handleSelectMessage = row => {
        const messageId = row && row.original ? row.original["id"] : null;
        setSelectedMessage(messageId);
    };

    const handleSelectFunder = row => {
        const rorId = row && row.original ? row.original["ror"] : null;
        setSelectedFunder(rorId);
    };

    useEffect(() => {
        if (selectedArticle) {
            getArticleDetails(selectedArticle)
                .then(res => setSelectedArticleDetails(res.data))
                .then(setIsArticleModalOpen(true))
        }
    }, [selectedArticle]);

    useEffect(() => {
        if (selectedMessage) {
            getMessageDetails(selectedMessage)
                .then(res => setSelectedMessageDetails(transformData(res.data[0])))
                .then(setIsMessageModalOpen(true))
        }
    }, [selectedMessage]);

    const getMessagesData = ({ searchValue, offset, itemsPerPage, sortBy, sortDir }) => {
        return getInstitutionMessages(encodeURIComponent(selectedFunder), searchValue, offset, itemsPerPage, sortBy, sortDir)
            .then(res => ({ data: res.data.messages, total: res.data.total }))
    };

    const tabs = [
        {
            id: '1',
            title: 'In pipeline',
            content: (
                <>
                    {inPipeLineArticles &&
                        <Table
                            data={inPipeLineArticles}
                            columns={inPipeLineArticlesColumns}
                            sortBy={sortBy}
                            sortDir={sortDir}
                            setSortBy={setSortBy}
                            setSortDir={setSortDir}
                            onSelectRow={handleSelectArticle}
                        />}
                </>
            ),
        },
        {
            id: '2',
            title: 'Published',
            content: (
                <>
                    {publishedArticles && (
                        <Table
                            data={publishedArticles}
                            columns={publishedArticlesColumns}
                            onSelectRow={handleSelectArticle}
                        />
                    )}
                </>

            ),
        },
        {
            id: '3',
            title: 'Messages',
            content: (
                <CustomTable
                    columns={funderMessagesColumns}
                    itemsPerPage={itemsPerPage}
                    getTableData={getMessagesData}
                    getFilterData={null}
                    sortByDefault="id"
                    sortDirDefault="desc"
                    search={false}
                    onSelectRow={handleSelectMessage}
                />
            ),
        },
    ];

    const renderArticlesPerFunder = () => {
        if (!selectedFunder && !loading && !error) {
            return (
                <Table
                    title="Articles per funder"
                    data={articlesPerFunder}
                    columns={articlesPerInstitutionColumns}
                    onSelectRow={handleSelectFunder}
                />
            );
        }
        return null;
    };

    const renderFunderCharts = () => {
        if (!loading && !error && selectedFunder && institutionArticlesPerMonth && institutionJournals) {
            return (
                <>
                    <MDBRow className="mt-4 d-flex justify-content-center">
                        <MDBCol md='8' className="mb-5 mt-4">
                            <Card
                                title="Funder articles per month:"
                                content={<BarChart data={institutionArticlesPerMonth} xAxisKey="month" yAxisKey="count" />}
                            />
                        </MDBCol>
                        <MDBCol md='4' className="mb-5 mt-4">
                            <Card
                                title="Funder journals:"
                                content={<PieChart data={institutionJournals} labelKey="name" valueKey="count" />}
                            />
                        </MDBCol>
                    </MDBRow>
                    <NavigationTabs tabs={tabs} />
                </>
            );
        }
        return null;
    };

    return (
        <div className="container">
            <div className="mt-5 pb-3">
                <div className="d-flex justify-content-end mb-4">
                    <Field component={AsyncInput} loadOptions={loadRorOptions} setValue={setSelectedFunder} placeholder="Select funder" />
                </div>

                {renderArticlesPerFunder()}

                {loading && <Loader />}
                {!loading && error && <ErrorMessage />}
                {!loading && !error && selectedFunder && renderFunderCharts()}

                <ArticleDetailsModal
                    isOpen={isArticleModalOpen}
                    setIsOpen={setIsArticleModalOpen}
                    articleDetails={selectedArticleDetails}
                    tabItems={userSettings.details}
                />
                <ArticleDetailsModal
                    isOpen={isMessageModalOpen}
                    setIsOpen={setIsMessageModalOpen}
                    articleDetails={selectedMessageDetails}
                    tabItems={userSettings.messagesDetails}
                />
            </div>
        </div>
    );
};

export default withFormik({})(Funders);